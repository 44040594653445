import Vue, { computed } from "vue"
import VueRouter from "vue-router"

import { useAuthStore } from "@/stores/auth"

function stringToNullBoolean(s: string) {
  if (s === "null") {
    return null
  }
  if (s === "false") {
    return false
  }
  return true
}

function isAuthenticated() {
  const authStore = useAuthStore()
  return authStore.isAuthenticated
}

// Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("../views/PageNotFound.vue"),
  },
  {
    path: "/",
    name: "MainPage",
    component: () => import("../views/MainPage.vue"),
  },
  {
    path: "/zakup",
    name: "PoZakupie",
    component: () => import("../views/PoZakupie.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("../views/FAQ.vue"),
  },
  {
    path: "/koszyk",
    name: "koszyk",
    props: (route) => ({
      //TODO: guard the props
      item: route.query.item,
      product: route.query.product,
    }),
    component: () => import("../views/Koszyk.vue"),
  },
  {
    path: "/blog",
    component: () => import("../views/Blog.vue"),
    children: [
      {
        path: "",
        name: "blogList",
        component: () => import("../components/blog/BlogList.vue"),
      },
      {
        path: ":id",
        name: "blogPost",
        props: (route) => ({
          id: route.params.id,
        }),
        component: () => import("../components/blog/BlogPost.vue"),
      },
    ],
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/regulamin",
    component: () => import("../views/Regulamin.vue"),
    children: [
      {
        path: "sprzedaz",
        name: "RegulaminSprzedazy",
        component: () => import("../components/others/regulamin/RegulaminSprzedazy.vue"),
      },
      {
        path: "platforma",
        name: "RegulaminPlatformy",
        component: () => import("../components/others/regulamin/RegulaminPlatformy.vue"),
      },
      {
        path: "opinie",
        name: "RegulaminOpinie",
        component: () => import("../components/others/regulamin/RegulaminOpinie.vue"),
      },
      {
        path: "polityka",
        name: "Politykaprywatnosci",
        component: () => import("../components/others/regulamin/RegulaminPolityka.vue"),
      },
    ],
  },
  {
    path: "/aktywacja",
    name: "Aktywacja",
    component: () => import("../views/Aktywacja.vue"),
  },
  {
    path: "/password",
    name: "Password",
    component: () => import("../views/Password.vue"),
  },
  {
    path: "/kurs/",
    name: "KursList",
    component: () => import("../views/KursList.vue"),
  },
  {
    path: "/kurs/:subjectSlug",
    name: "Kurs",
    props: (route) => ({
      subjectSlug: route.params.subjectSlug,
    }),
    component: () => import("../views/Kurs.vue"),
  },
  {
    path: "/przerwa",
    name: "Przerwa",
    component: () => import("../views/Przerwa.vue"),
  },
  {
    path: "/film",
    name: "VideoList",
    component: () => import("../views/VideoList.vue"),
  },
  {
    path: "/dashboard/:subjectSlug",
    beforeEnter: (to, from, next) => {
      if (!isAuthenticated()) next({ name: "Kurs", params: { subjectSlug: to.params.subjectSlug } })
      else next()
    },
    props: (route) => ({
      subjectSlug: route.params.subjectSlug,
    }),
    component: () => import("../views/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        beforeEnter: (to, from, next) => {
          next({ name: "kurs", params: { subjectSlug: to.params.subjectSlug } })
        },
        component: () => import("../components/dashboard/Dashboardv2.vue"),
      },
      {
        path: "kurs",
        name: "kurs",
        props: (route) => ({
          subjectSlug: route.params.subjectSlug,
        }),
        component: () => import("../components/dashboard/DashboardKurs.vue"),
      },
      {
        path: "zadania",
        name: "zadania",
        props: (route) => ({
          subjectSlug: route.params.subjectSlug,
          exclude_tags: ["matura"],
          filters1: route.query.filters1 ? route.query.filters1.split(",") : [],
          filters2: route.query.filters2 ? route.query.filters2.split(",").map(stringToNullBoolean) : [],
          page: route.query.page ? Number(route.query.page) : 1,
        }),
        component: () => import("../components/dashboard/DashboardZadania.vue"),
      },
      {
        path: "maturalne",
        name: "maturalne",
        props: (route) => ({
          subjectSlug: route.params.subjectSlug,
          include_tags: ["matura"],
          title: "Zestawy Maturalne",
          linkTo: "zadaniematuralne",
          filters1: route.query.filters1 ? route.query.filters1.split(",") : [],
          filters2: route.query.filters2 ? route.query.filters2.split(",").map(stringToNullBoolean) : [],
          page: route.query.page ? Number(route.query.page) : 1,
        }),
        component: () => import("../components/dashboard/DashboardZadania.vue"),
      },
      {
        path: "zadania/:uuid",
        name: "zadanie",
        props: (route) => ({
          uuid: route.params.uuid,
          backlink: { link: route.query.backlink_link, name: route.query.backlink_name },
          next_links: route.query.next_links ? route.query.next_links.split(",") : [],
          list_uniqueKey: route.query.list_uniqueKey,
          isAdmin: false, // TODO: hasAdminAccess(store.getters["courses/currentSubject"]),
          subjectSlug: route.params.subjectSlug,
        }),
        component: () => import("../components/dashboard/zadania/Zadanie.vue"),
      },
      {
        path: "maturalne/:uuid",
        name: "zadaniematuralne",
        props: (route) => ({
          uuid: route.params.uuid,
          backlink: { link: route.query.backlink_link, name: route.query.backlink_name },
          next_links: route.query.next_links ? route.query.next_links.split(",") : [],
          list_uniqueKey: route.query.list_uniqueKey,
          isAdmin: false, // TODO: hasAdminAccess(store.getters["courses/currentSubject"]),
          subjectSlug: route.params.subjectSlug,
        }),
        component: () => import("../components/dashboard/zadania/Zadanie.vue"),
      },
      {
        path: "wiadomosci",
        name: "wiadomosci",
        beforeEnter: (to, from, next) => {
          const msgPromise = store.dispatch("msg/loadMessageList", to.params.subjectSlug)
          Promise.all([msgPromise]).then(next)
        },
        props: (route) => ({
          subjectSlug: route.params.subjectSlug,
        }),
        component: () => import("../components/dashboard/DashboardWiadomosci.vue"),
      },
      {
        path: "ustawienia",
        name: "ustawieniaKurs",
        props: (route) => ({
          subjectSlug: route.params.subjectSlug,
        }),
        component: () => import("../components/dashboard/UstawieniaKurs.vue"),
      },
      // {
      //   path: 'exam',
      //   name: 'exam',
      //   component: () => import('../components/dashboard/exam/DashboardExam.vue')
      // },
      {
        path: "kurs/:unit_uuid", //TODO: possible to use slug here and pass uuid to component
        name: "lekcje",
        props: (route) => ({ unit_uuid: route.params.unit_uuid }),
        component: () => import("../components/dashboard/DashboardUnits.vue"),
      },
      {
        path: "kurs/:unit_uuid/:lesson_uuid",
        name: "lesson",
        props: (route) => ({
          unit_uuid: route.params.unit_uuid,
          lesson_uuid: route.params.lesson_uuid,
          subjectSlug: route.params.subjectSlug,
        }),
        component: () => import("../components/dashboard/article/Lesson.vue"),
      },
      {
        path: "fiszki",
        name: "fiszki",
        component: () => import("../components/dashboard/fiszki/view/FiszkiView.vue"),
      },
      {
        path: "fiszki/result",
        name: "fiszki-result",
        component: () => import("../components/dashboard/fiszki/result/FiszkiResult.vue"),
      },
      {
        path: "fiszki/:mode/:uuid/",
        name: "fiszki-mode",
        props: (route) => ({
          uuid: route.params.uuid,
          mode: route.params.mode,
          slug: route.params.subjectSlug,
        }),
        component: () => import("../components/dashboard/fiszki/game/FiszkiGame.vue"),
      },
    ],
  },
  {
    path: "/profil",
    beforeEnter: (to, from, next) => {
      if (!isAuthenticated()) next({ name: "MainPage" })
      else next()
    },
    component: () => import("../views/Profil.vue"),
    children: [
      {
        path: "",
        name: "profilDane",
        component: () => import("../components/mojProfil/ProfilDane.vue"),
      },
      {
        path: "kurs",
        name: "profilKurs",
        component: () => import("../components/mojProfil/ProfilKurs.vue"),
      },
      {
        path: "delete",
        name: "profilDelete",
        component: () => import("../components/mojProfil/ProfilDelete.vue"),
      },
      {
        path: "ustawienia",
        name: "profilUstawienia",
        component: () => import("../components/mojProfil/ProfilUstawienia.vue"),
      },
    ],
  },
  {
    path: "/miasto",
    name: "CitiesList",
    component: () => import("../views/CitiesList.vue"),
  },
  {
    path: "/miasto/:cityName",
    name: "GeoLandingPage",
    props: (route) => ({
      cityName: route.params.cityName,
    }),
    component: () => import("../views/GeoLandingPage.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  // store.dispatch('initialize')
  //   .then(next()) TODO:
  const authStore = useAuthStore()
  authStore.initialize().then(() => next())
})

export default router
