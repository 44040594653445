const courses = [
  {
    slug: "wiedza-o-spoleczenstwie",
    name: "Wiedza o Społeczeństwie",
    short: "WOS",
  },
  {
    slug: "geografia-rozszerzona",
    name: "Geografia",
  },
  {
    slug: "matematyka",
    name: "Matematyka Podstawowa",
  },
  {
    slug: "matematyka-rozszerzona",
    name: "Matematyka Rozszerzona",
  },
  {
    slug: "biologia",
    name: "Biologia",
  },
  {
    slug: "fizyka",
    name: "Fizyka",
  },
  {
    slug: "informatyka",
    name: "Informatyka",
  },
]

// "fizyka" => "Fizyka"
const capitalize = (val) => {
  if (!val) return ""
  return val.charAt(0).toUpperCase() + val.slice(1)
}

// "2021-10-27T13:44:06.438127Z" (ISO data) => "27.10.2021"
const dateFormat = (data) => {
  var dzien = data.slice(8, 10) + "." + data.slice(5, 7) + "." + data.slice(0, 4)
  return dzien
}
const months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
]

// "2022-08-31" -> "31 Sierpień 2022"
const dateFormatMonth = (data) => {
  var accessmonth = months[parseInt(data.slice(5, 7)) - 1]
  var date = data.slice(8, 10) + " " + accessmonth + " " + data.slice(0, 4)
  return date
}

const monthName = (number) => {
  return months[parseInt(number) - 1]
}

const iconSrc = (subjectSlug) => {
  return require(`@/components/assets/mainPage/icon-${subjectSlug}.svg`)
}

const subjectName = (subjectSlug) => {
  return courses.find((course) => course.slug === subjectSlug)?.name
}

const subjectShortName = (subjectSlug) => {
  const course = courses.find((course) => course.slug === subjectSlug)
  return course.short ? course.short : course.name
}

const login = (caller, form) => {
  caller.isWaitingForResponse = true
  caller.errorLogin = false
  caller.$store
    .dispatch("auth/login", {
      login: form.email,
      password: form.password,
      storage: form.remember ? "local" : "session",
    })
    .then(() => {
      caller.$store.dispatch("profile/loadProfile").then(() => {
        caller.$emit("logged_in")
      })
    })
    .catch(() => {
      caller.errorLogin = true
    })
    .finally(() => {
      caller.isWaitingForResponse = false
    })
}

const updateFormValue = (field, newValue) => {
  field.$emit("input", newValue)

  if (field.validationsEnabled) {
    field.validateField(newValue)
  }
}

const commaNumber = (pointedNumber) => {
  let result = pointedNumber.toString().replace(".", ",")
  if (result.includes(",")) {
    return result
  } else {
    return result + ",00"
  }
}

const pointNumber = (commadNumber) => {
  return commadNumber.replace(",", ".")
}

export const isKeyNumber = (keyEvent) => {
  let char = String.fromCharCode(keyEvent.keyCode)
  return /\d/.test(char)
}

/**
 * Works like python's rstrip:
 * https://docs.python.org/3/library/stdtypes.html#str.rstrip
 */
const rstrip = (string, charsToRemove) => {
  let end = string.length - 1
  while (charsToRemove.includes(string[end])) {
    end--
  }
  return string.slice(0, end + 1)
}

const YANDEX_ID = 70925977

const yandexReachGoal = (goalId, params) => {
  window.ym?.(YANDEX_ID, "reachGoal", goalId, params)
}
const yandexInit = (params) => {
  window.ym?.(YANDEX_ID, "init", params)
}

const analyticsReachGoal = (object, enabled) => {
  if (enabled) {
    window.dataLayer?.push(object)
  }
}

export const focusFirstFocusableChild = (htmlElement) => {
  htmlElement
    .querySelector(
      `a[href]:not([tabindex='-1']),
    area[href]:not([tabindex='-1']),
    input:not([disabled]):not([tabindex='-1']),
    select:not([disabled]):not([tabindex='-1']),
    textarea:not([disabled]):not([tabindex='-1']),
    button:not([disabled]):not([tabindex='-1']),
    iframe:not([tabindex='-1']),
    [tabindex]:not([tabindex='-1']),
    [contentEditable=true]:not([tabindex='-1'])`
    )
    .focus()
}

// Returns a shuffled version of the provided array
const shuffleArray = (arr) => {
  const array = [...arr]
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export default {
  capitalize,
  dateFormat,
  dateFormatMonth,
  iconSrc,
  subjectName,
  subjectShortName,
  login,
  updateFormValue,
  commaNumber,
  pointNumber,
  rstrip,
  yandexReachGoal,
  yandexInit,
  YANDEX_ID,
  shuffleArray,
  analyticsReachGoal,
  monthName,
}
